// src/LoginPage.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import backgroundImage from '../images/game360Background.webp'; // Adjust the path as necessary

const LogIn = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    // You might want to validate the phone number format before navigating
    navigate(`/?serviceId=game360&ani=${phoneNumber}`);
  };

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    // Only allow digits
    const numericValue = value.replace(/\D/g, "");
    setPhoneNumber(numericValue);
  };

  return (
    <div className="login-container">
      <style>{`
        body {
          font-family: 'Poppins', sans-serif;
          margin: 0;
          padding: 0;
          background: url(${backgroundImage}) no-repeat center center fixed;
          background-size: cover;
          height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .login-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
        }

        .login-box {
          position: relative;
          background: rgba(0, 0, 0, 0.7);
          border-radius: 20px;
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
          padding: 50px 40px; 
          width: 500px; 
          text-align: center;
          backdrop-filter: blur(15px);
          overflow: hidden; 
          transform: skew(-10deg); 
        }

        .login-box-inner {
          transform: skew(10deg); 
        }

        .login-title {
          font-size: 40px; 
          color: #ff4081;
          margin-bottom: 25px; 
          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
          letter-spacing: 1px;
        }

        .login-form {
          display: flex;
          flex-direction: column;
        }

        .input-group {
          margin-bottom: 20px; 
          text-align: left;
        }

        .input-group label {
          font-size: 18px; 
          color: #59E7F7; /* Changed to a brighter color for visibility */
          margin-bottom: 5px;
          display: block;
        }

        .input-group input {
          width: 100%;
          padding: 14px; 
          border: none; 
          border-radius: 10px;
          font-size: 18px; 
          transition: box-shadow 0.3s ease;
          outline: none;
          background: rgba(255, 255, 255, 0.1); 
          color: #ffffff; 
        }

        .input-group input:focus {
          box-shadow: 0 0 10px rgba(255, 64, 129, 0.7); 
        }

        .login-button {
          background: linear-gradient(135deg, #ff4081, #ff8e8e);
          color: white;
          padding: 14px; 
          border: none;
          border-radius: 10px;
          cursor: pointer;
          font-size: 20px; 
          transition: background 0.3s ease, transform 0.3s ease;
          margin-top: 10px;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); 
        }

        .login-button:hover {
          background: linear-gradient(135deg, #ff8e8e, #ff4081);
          transform: translateY(-2px); 
        }

        .login-footer {
          font-size: 16px; 
          color: #cccccc; 
          margin-top: 20px;
        }

        .login-footer a {
          color: #ff4081;
          text-decoration: none;
          font-weight: bold;
        }

        .login-footer a:hover {
          text-decoration: underline;
        }
      `}</style>

      <div className="login-box">
        <div className="login-box-inner">
          <h2 className="login-title">Game360</h2>
          <form onSubmit={handleSubmit} className="login-form">
            <div className="input-group">
              <label htmlFor="number">Phone Number</label>
              <input
                type="tel"
                id="number"
                placeholder="Enter your phone number"
                value={phoneNumber}
                onChange={handlePhoneChange} // Updated to use handlePhoneChange
                required
                
                maxLength="12" // Limit input length to 10 digits
              />
            </div>
            {/* <div className="input-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                placeholder="Enter your password"
                required
              />
            </div> */}
            <button type="submit" className="login-button">
              Login
            </button>
          </form>
          {/* <p className="login-footer">
            Don't have an account? <a href="/signup">Sign Up</a>
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default LogIn;

